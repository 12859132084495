<template>
  <div>
    <TableFilters :name="name" @name-change="name = $event" />

    <a-divider />

    <a-button
      type="primary"
      :style="{ marginBottom: '15px' }"
      @click="$router.push({ name: 'makes-new' }).catch(_err => {})"
    >
      Создать новую марку
    </a-button>

    <a-table
      bordered
      :columns="columns"
      :loading="loading"
      :row-key="record => record.id"
      :pagination="{ current: pageNumber, total: makesTotal }"
      :data-source="makes"
      @change="handleTableChange"
    >
      <div
        slot="is_active"
        slot-scope="record"
        style="display: flex; justify-content: center"
      >
        <a-popconfirm
          ok-text="Да"
          cancel-text="Нет"
          placement="topRight"
          :title="
            `Вы уверены, что хотите ${
              record.is_active ? 'отключить' : 'включить'
            } значение?`
          "
          @confirm="updateMake(record, 'is_active')"
        >
          <a-checkbox v-model="record.is_active" />
        </a-popconfirm>
      </div>

      <span
        slot="actions"
        slot-scope="record"
        :style="{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }"
      >
        <a-button
          icon="edit"
          title="Редактировать"
          type="primary"
          @click="
            $router
              .push({
                name: 'makes-edit',
                params: { id: record.id },
              })
              .catch(_err => {})
          "
        />
        <a-popconfirm
          ok-text="Да"
          cancel-text="Нет"
          placement="topRight"
          title="Вы уверены что хотите удалить эту марку?"
          @confirm="deleteMake(record)"
        >
          <a-button
            :style="{ marginLeft: '15px' }"
            type="danger"
            title="Удалить"
            icon="delete"
          />
        </a-popconfirm>
      </span>
    </a-table>
  </div>
</template>
<script>
import columns from './table_columns'
import TableFilters from './TableFilters.vue'
import { debounce } from '@/utils'
import {
  MAKES,
  GET_MAKES,
  UPDATE_MAKE,
  DELETE_MAKE,
  MAKES_TOTAL,
} from '@/store/types'

export default {
  components: { TableFilters },

  data() {
    return {
      columns,
      loading: false,
      name: '',
      pageNumber: 1,
    }
  },

  computed: {
    makes() {
      return this.$store.getters[MAKES]
    },
    makesTotal() {
      return this.$store.getters[MAKES_TOTAL]
    },
  },

  watch: {
    name(value) {
      this.pageNumber = 1
      this.$router
        .replace({
          name: 'makes',
          query: {
            ...this.$route.query,
            name: value,
            pageNumber: 1,
          },
        })
        .catch(err => err)

      this.getMakes()
    },
  },

  created() {
    this.name = this.$route.query.name || this.name

    this.getMakes()
  },

  methods: {
    getMakes: debounce(function() {
      const { name, pageNumber } = this

      this.$store.dispatch(GET_MAKES, {
        name,
        pageNumber,
      })
    }, 300),

    handleTableChange(pagination) {
      this.$router.replace({
        name: 'makes',
        query: { ...this.$route.query, pageNumber: pagination.current },
      })

      this.pageNumber = pagination.current

      this.getMakes()
    },

    updateMake(make, field) {
      this.$store.dispatch(UPDATE_MAKE, {
        ...make,
        [field]: !make[field],
      })
    },

    deleteMake(make) {
      this.$store.dispatch(DELETE_MAKE, make)
    },
  },
}
</script>
