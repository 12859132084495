<template>
  <a-row>
    <a-col :span="6">
      <a-input
        placeholder="Название марки"
        :value="name"
        @input="$emit('name-change', $event.target.value)"
      />
    </a-col>
  </a-row>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: 'all',
    },
  },
}
</script>
